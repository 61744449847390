import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import axios from 'axios';
import Notification from './Notification';
import Draggable from 'react-draggable';
import { Tooltip } from 'react-tooltip'

const noteIcon = encodeURIComponent('<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1692_1844)"><circle cx="50" cy="50" r="50" fill="#FFFFCC"/><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3366 81.3663C20.8383 81.3663 18 78.5281 18 75.0297V24.3366C18 20.8383 20.8383 18 24.3366 18H75.0297C78.5281 18 81.3663 20.8383 81.3663 24.3366V58.4936C81.3663 60.1755 80.6983 61.786 79.5102 62.9741L62.9741 79.5102C61.786 80.6983 60.1755 81.3663 58.4936 81.3663H24.3366ZM22.2244 75.0297C22.2244 76.1967 23.1696 77.1419 24.3366 77.1419H58.132V64.4687C58.132 60.9703 60.9703 58.132 64.4687 58.132H77.1419V24.3366C77.1419 23.1696 76.1967 22.2244 75.0297 22.2244H24.3366C23.1696 22.2244 22.2244 23.1696 22.2244 24.3366V75.0297ZM74.1558 62.3564H64.4687C63.3016 62.3564 62.3564 63.3016 62.3564 64.4687V74.1558L74.1558 62.3564ZM46.7122 32.3563C46.7122 30.9893 47.8204 29.8811 49.1874 29.8811C50.5545 29.8811 51.6627 30.9893 51.6627 32.3563V42.2573H61.5637C62.9307 42.2573 64.0389 43.3655 64.0389 44.7326C64.0389 46.0996 62.9307 47.2078 61.5637 47.2078H51.6627V57.1088C51.6627 58.4759 50.5545 59.5841 49.1874 59.5841C47.8204 59.5841 46.7122 58.4759 46.7122 57.1088V47.2078H36.8112C35.4441 47.2078 34.3359 46.0996 34.3359 44.7326C34.3359 43.3655 35.4441 42.2573 36.8112 42.2573H46.7122V32.3563Z" fill="#333333"/></g><defs><clipPath id="clip0_1692_1844"><rect width="100" height="100" fill="white"/></clipPath></defs></svg>'); 

const MessageBox = styled.div`
  background-image: url('data:image/svg+xml,${noteIcon}');
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: auto;
  &.expanded {
    background-image: none;
    background-color: #f4f4e4;
    border: 1px solid #ccc;
    padding: 6px;
    width: 250px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }
`;

const Input = styled.textarea`
  width: -webkit-fill-available;
  height: 3em;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  resize: vertical;
  background-color: transparent;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubmitButton = styled.button`
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  background-color: #999;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
`;

const ExpandableMessageBox = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [message, setMessage] = useState('');
  const [droppedLocation, setDroppedLocation] = useState({ x: 0, y: 0 });
  const [relativeDroppedLocation, setRelativeDroppedLocation] = useState({ x: 0, y: 0 });

  const focusInput = useCallback(() => {
    if (isExpanded) {
      document.querySelector('.visible').focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest('.message-box')) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    focusInput();
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isExpanded]);

  const handleSubmit = async () => {
    try {
      // Get metadata about the current page and browser
      const sanitizedMessage = DOMPurify.sanitize(message);
      const currentPage = window.location.href;
      const userAgent = navigator.userAgent;
      const language = navigator.language;
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      const scrollX = window.pageXOffset;
      const scrollY = window.pageYOffset;
      const timestamp = new Date().toISOString();

      const isLocal = window.location.hostname === 'localhost' || window.location.port === '3000';

      let serverUrl = null;
      if (isLocal) {
        // Use Node server
        serverUrl = 'http://localhost:4000/api/messages';
      } else {
        // Use PHP server on Dreamhost
        serverUrl = '/server.php';
      }

      // Send the message and metadata to the server
      await axios.post(serverUrl, {
        message: sanitizedMessage,
        currentPage,
        userAgent,
        language,
        screenWidth: clientWidth,
        screenHeight: clientHeight,
        scrollX,
        scrollY,
        viewportWidth: innerWidth,
        viewportHeight: innerHeight,
        timestamp,
        x: relativeDroppedLocation.x,
        y: relativeDroppedLocation.y,
      });

      // Reset the message and close the expanded state
      setMessage('');
      setIsExpanded(false);
      handleShowNotification('Comment has been received!');
    } catch (error) {
      handleShowNotification('Error submitting form: ' + error.message);
      console.error('Error submitting message:', error);
    }
  };

  const handleBoxClick = (e) => {
    // Check if the target of the click event is the message box itself
    if (e.target === e.currentTarget) {
      setIsExpanded(true);
      if (isExpanded) {
        focusInput();
      }
    }
  };

  const handleCancelClick = () => {
    setMessage('');
    setIsExpanded(false);
  };

  const handleShowNotification = (message) => {
    setShowNotification(true);
    setNotificationMessage(message);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // hide notification after 3 seconds
  };

  const handleDragStop = (e, ui) => {
    const { x, y } = ui;
    setDroppedLocation({ x, y });
    setRelativeDroppedLocation({ x: e.clientX, y: e.clientY });
  };

  return (<>
    <Notification 
      message={notificationMessage} 
      setShowNotification={setShowNotification} 
      showNotification={showNotification} 
    />
    <Draggable
      onStop={handleDragStop}
      position={droppedLocation}
    >
      <MessageBox
        className={`message-box ${isExpanded ? 'expanded' : ''}`}
        onClick={handleBoxClick}
        data-tooltip-id="my-tooltip-data-html"
        
        data-tooltip-html="<div><h3>Let me know what you think</h3><p>Ask a question, or share your ideas.</p></div>"
      >
        {isExpanded && (
          <>
            <Input
              className={isExpanded ? 'visible' : ''}
              type="text"
              placeholder="Share your thoughts"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />
            <ButtonContainer>
              <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
            </ButtonContainer>
          </>
        )}
      </MessageBox>
    </Draggable>
    {!isExpanded && (
      <Tooltip
        id="my-tooltip-data-html"
        style={{
          backgroundColor: "rgba(30, 30, 30, 0.8)",
          color: "#fff",
          zIndex: 999999,
          padding: "10px",
          borderRadius: "10px",
          outline: "2px solid #eee",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)"
        }}
      />
    )}
  </>
  );
};

export default ExpandableMessageBox;