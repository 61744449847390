import React from 'react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationText = styled.p`
  margin: 0;
  color: #333;
`;

const Notification = ({ message, setShowNotification, showNotification }) => {
  if (!showNotification) return null;
  return (
    <NotificationWrapper>
      <NotificationText>{message}</NotificationText>
      {/* <button onClick={() => setShowNotification(false)}>X</button> */}
    </NotificationWrapper>
  );
};

export default Notification;